/* eslint-disable max-len,no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */

import React, { useState, useEffect, useRef, lazy, Suspense } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Header } from '../components/Header';
// import MainSlider from '../components/MainSlider';
// import SliderBanner from '../components/SliderBanner';
import {
  changeAudioCurrentTimePercentAction,
  setCurrentDurationAction,
  setCurrentFullDurationAction, 
  setCurrentFullTimeAction, 
  setCurrentTimeAction,
  setVideoStreamOpen,
  setFlowsInfo,
  setVideoStreamPlaying,
  playAudioPlaybackAction,
  changeAudioFlowAction,
  setPlaylistLastSong,
} from '../redux/actions';
// import BlockPlayer from '../components/BlockPlayer';
import { getSiteBackground, getVideoStreamSrc } from './requests'; //getSongPicture
import { PROGRAMS } from '../constants/routes';
import './index.css';
import { getDomain } from '../helpers/fetchRequest';
// import Video from '../components/Video';
import videojs from 'video.js';
const Video = lazy(() => import('../components/Video'));
// const videojs = lazy(() => import('video.js'));
const BlockPlayer = lazy(() => import('../components/BlockPlayer'));
const MainSlider = lazy(() => import('../components/MainSlider'));
const SliderBanner = lazy(() => import('../components/SliderBanner'));
const AdvertisementLine = lazy(() => import('../components/AdvertisementLine'));



function Layout({
  children,
  audioFlow,
  audioPlayback,
  flow,
  volume,
  audioCurrentFullTime,
  setCurrentDuration,
  setCurrentTime,
  setCurrentFullTime,
  setCurrentFullDuration,
}) {
  const { streamName, flowsInfo, isVideoStreamPlaying, isMenuOpen } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [playerOpen, setPlayerOpen] = useState(false);
  const [siteData, setSiteData] = useState({});
  const [title, setTitle] = useState('Initial title');
  const audio = useRef(null);
  const [videoSrc, setVideoSrc] = useState(null);

  function handleScroll() {
    const scrollTop = window.scrollY;
    const width = window.outerWidth;
    if (scrollTop >= 80 && width >= 1200) {
      setPlayerOpen(true);
    } else {
      setPlayerOpen(false);
    }
  }

  window.addEventListener('scroll', handleScroll);

  const { pathname } = useLocation();
  const regex = new RegExp('/programs/\\w');
  const isProgramsDetailPage = regex.test(pathname);

  const calculateTime = (time) => {
    const floorTime = Math.floor(time);
    const calculatedMinutes = Math.floor(floorTime / 60);
    const calculatedSeconds = Math.floor(floorTime % 60);
    const formedTime = (value) => (value < 1 ? '00' : value < 10 ? `0${value}` : value);
    return `${formedTime(calculatedMinutes)}:${formedTime(calculatedSeconds)}`;
  };

  async function processingRequest() {
    setSiteData((await getSiteBackground()).data);
    setLoaded(true);
    setVideoSrc((await getVideoStreamSrc()).data)
  }
  useEffect(() => {
    processingRequest();
    console.log("LAYOUT RERENDER");
  }, []);

  useEffect(() => {
    if (loaded) {
      const html = document.getElementById('html');
      html.style.backgroundImage = `url(${siteData.backgroung})`;
    }
  }, [loaded]);

  useEffect(() => {
    audio.current.addEventListener('loadedmetadata', (event) => {
      // console.log('audioFlow', audioFlow);
      // console.log('audioPlayback', audioPlayback);
      // console.log('streamName', streamName);

      const duration = calculateTime(event.target.duration);

      setCurrentFullDuration(event.target.duration);
      setCurrentFullTime(event.target.currentTime);

      setCurrentDuration(duration);
    });
  }, [flow]);
  // if (audio.current) {
  //   audio.current.addEventListener('timeupdate', (event) => {
  //     const currentTime = calculateTime(event.target.currentTime);
  //     setCurrentTime(currentTime);
  //   });
  // }

  useEffect(() => {
    const host = window.document.location.host.replace(/:.*/, '');
    const wsConnection = new WebSocket(`wss://${host}`);

    wsConnection.onopen = function() {
      console.log("Соединение установлено.");
    };

    wsConnection.onclose = function(event) {
      if (event.wasClean) {
        console.log('Соединение закрыто чисто');
      } else {
        console.log('Обрыв соединения'); // например, "убит" процесс сервера
      }
      console.log('Код: ' + event.code + ' причина: ' + event.reason);
    };

    wsConnection.onmessage = async function(event) {
      let meta = JSON.parse(event.data);
      // console.log("meta", meta);
      let artist, title, cover;
      if (meta.value.indexOf(" - ") != -1) {
        [artist, title] = meta.value.split(" - ");
        cover = meta.info.picture;
        
      } else {
        artist = ""; 
        title = meta.value; 
        cover = meta.picture;
      }
      const obj = {[meta.flow]: {"artist": artist, "title": title, "cover": cover}}
      // console.log(obj)
      dispatch(setFlowsInfo(obj));
      if (meta.flow == "В Эфире")
        dispatch(setPlaylistLastSong(meta.info));
      
    }

    wsConnection.onerror = function(error) {
      console.log(error);
    };

    return () => {
      wsConnection.close(1000, "component rerender");
      console.log('connection closed');
    }
  }, []);

  useEffect(() => {
    audio.current.currentTime = audioCurrentFullTime;
  }, [audioCurrentFullTime]);

  useEffect(() => {

    if (audioPlayback) {
      audio.current.play();
      dispatch(changeAudioFlowAction(audioFlow));
    }
    if (!audioPlayback) {
      audio.current.pause();
      dispatch(changeAudioFlowAction(""));
      audio.currentTime = 0;
    }
  }, [audioFlow, audioPlayback, flow]);

  useEffect(() => {
    audio.current.volume = volume;
  }, [volume]);

  //------------------------- Video JS -------------------------------------

  const playerRef = useRef(null);
  const { isVideoStreamOpened } = useSelector((state) => state);
  const [isOpened, setIsOpened] = useState(isVideoStreamOpened);
  const [pause, setPause] = useState(true);
  // const [videoJsOptions, setVideoJsOptions] = useState({});
  // let handlePlayerReady

  useEffect(() => {
    setIsOpened(isVideoStreamOpened);
  }, [isVideoStreamOpened])

  let videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
  }

  // setVideoJsOptions({
  //   autoplay: false,
  //   controls: true,
  //   responsive: true,
  //   fluid: true,
  //   sources: [{
  //     src: null,
  //     type: 'video/youtube'
  //   }]
  // });

  // useEffect(() => {
  //   console.log("videoSrc", videoSrc);
  //   setVideoJsOptions({
  //     autoplay: false,
  //     controls: true,
  //     responsive: true,
  //     fluid: true,
  //     sources: [
  //       {
  //         src: videoSrc,
  //         type: 'application/x-mpegURL',
  //       }
  //     ]
  //   })
  // }, [videoSrc]);

  const handlePlayerReady = (player) => {
    playerRef.current = player;

    // You can handle player events here, for example:
    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
    player.on('play', () => {
      videojs.log('player is playing');
      setPause(false);
    });
    player.on('pause', () => {
      videojs.log('player is paused');
      setPause(true);
    });
  };

  const handleClosePlayer = (player) => {
      dispatch(setVideoStreamOpen(false));
      dispatch(setVideoStreamPlaying(false));
      setPause(true);
      // dispatch(playAudioPlaybackAction());
  }

  useEffect(() => {
    if (isVideoStreamPlaying) {
      playerRef.current.trigger('play');
    }
  }, [isVideoStreamPlaying])
  // console.log(audio);

  //--------------------------End of Video JS-------------------------------

  return (
    <>
      <audio src={audioFlow} ref={audio} />
      <script>
      </script>
      <div className={isMenuOpen ? "non-scroll" : ""}>
        
        <div className="wrapper">
        <Suspense fallback={<p>Loading</p>}>
          <AdvertisementLine />
        </Suspense>
          <div className="container">
            <Header />
            <main className="main">
              <Suspense fallback={<p>Loading</p>}>
                <MainSlider notRender={isProgramsDetailPage} />
              </Suspense>
              {children}
            </main>
            <Suspense fallback={<p>Loading</p>}>
              <SliderBanner type="left" />
            </Suspense>
            <Suspense fallback={<p>Loading</p>}>
              <SliderBanner type="right" />
            </Suspense>
            {/* <SliderBanner type="left" />
            <SliderBanner type="right" /> */}
          </div>
          <Suspense fallback={<p>Loading</p>}>
            <BlockPlayer open={playerOpen} />
          </Suspense>
        </div>
        <div className="videostream-dark-layout" 
          style={{display: `${isOpened ? 'flex' : 'none'}`}}
          onClick = {() => { handleClosePlayer()}}
        >
        </div>
        <div className = "videostream-container" style={{display: `${isOpened ? 'flex' : 'none'}`}}>
          <div className = "videostream-close-button" onClick = {() => { handleClosePlayer() }}>
            <svg class="icon icon-x" height="50" viewBox="0 0 50 50" width="50">
              <path d="M50.009,4.133 C50.009,4.133 29.131,24.998 29.131,24.998 C29.131,24.998 49.996,45.876 49.996,45.876 C49.996,45.876 45.867,50.009 45.867,50.009 C45.867,50.009 24.999,29.128 24.999,29.128 C24.999,29.128 4.120,49.994 4.120,49.994 C4.120,49.994 -0.012,45.865 -0.012,45.865 C-0.012,45.865 20.869,24.996 20.869,24.996 C20.869,24.996 0.006,4.120 0.006,4.120 C0.006,4.120 4.135,-0.012 4.135,-0.012 C4.135,-0.012 25.001,20.866 25.001,20.866 C25.001,20.866 45.876,0.004 45.876,0.004 C45.876,0.004 50.009,4.133 50.009,4.133 Z" id="path-1" class="cls-2" fill-rule="evenodd"></path>
            </svg>
            </div>
            <Suspense fallback={<p>Loading</p>}>
              {videoSrc && (
                <Video options={videoJsOptions} onReady={handlePlayerReady} isPause = {pause} url={videoSrc}/>
              )}
              
            </Suspense>
        </div>
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node,
  audioFlow: PropTypes.string.isRequired,
  audioPlayback: PropTypes.bool.isRequired,
  flow: PropTypes.object.isRequired,
  volume: PropTypes.number.isRequired,
};

Layout.defaultProps = {
  children: <div />,
};

const mapStateToProps = (state) => ({
  flow: state.flow,
  audioFlow: state.audioFlow,
  audioPlayback: state.audioPlayback,
  volume: state.volume,
  audioCurrentFullTime: state.audioCurrentFullTime,
});

const mapDispatchToProps = {
  changeAudioCurrentTimePercent: changeAudioCurrentTimePercentAction,
  setCurrentFullDuration: setCurrentFullDurationAction,
  setCurrentFullTime: setCurrentFullTimeAction,
  setCurrentDuration: setCurrentDurationAction,
  setCurrentTime: setCurrentTimeAction,
};

export default React.memo(connect(mapStateToProps, mapDispatchToProps)(Layout));
